import React from 'react'
import { useState } from 'react'
import { Image as Base, ImageProps as Props } from '@chakra-ui/react'

export default function(props: Props) {
  return <Base {...props} />
}

export function ImageWithLoader(props: Props) {
  const [loaded, setLoaded] = useState(false)

  return (
    <>
      {!loaded && <div style={{
        height: props.height ? String(props.height) : "44px"
      }} />}
      <Base
        {...props}
        onLoad={() => setLoaded(true)}
        style={{ display: loaded ? 'block' : 'none' }}
        className="object-cover"
      />
    </>
  )
}
