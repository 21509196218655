import { useQuery } from '@tanstack/react-query'
import { DeliveryPreferencesRequest } from '../../Features/BusinessSettings/DeliveryPreferences/DeliveryPreferences.types'
import { SelectOption } from '../../Features/BusinessSettings/ExpirationAndReminders/ExpirationAndReminders.types'
import { GeneralPreferencesRequest } from '../../Features/BusinessSettings/GeneralPreferences/GeneralPreferences.types'
import { legacyFetcher } from '../../hooks/fetcher'
import { useMutation } from '../../hooks/use-api'
import {
  BusinessNameRequest,
  BusinessSettingsResponse,
  ExpirationAndRemindersRequest,
  SigningPreferencesRequest,
  UseExpirationAndRemindersSettingsErrorReturn,
  UseMutateBusinessSettingsErrorReturn,
  UseMutateBusinessSettingsReturn,
} from './use-business-settings.types'

//
// todo should be useMutateBusinessSettings
//
export function useMutateBusinessSettings(withError = false) {
  const param = new URLSearchParams()
  param.append('endpoint', 'setting')
  param.append('action_type', 'edit')

  const api = useMutation<
    UseMutateBusinessSettingsReturn,
    UseExpirationAndRemindersSettingsErrorReturn
  >(
    `php/ajax_handler.php?${param}`,
    {
      endpoint: '/',
    },
    true,
  )

  return {
    ...api,
    run: (
      form:
        | ExpirationAndRemindersRequest
        | SigningPreferencesRequest
        | DeliveryPreferencesRequest
        | GeneralPreferencesRequest,
    ) => {
      const formData = new FormData()
      formData.append('postArray', JSON.stringify(form))
      return api.run(
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          //@ts-ignore
          body: new URLSearchParams(formData),
        },
        withError,
      )
    },
  }
}

const ajaxHandler = (param: URLSearchParams) => legacyFetcher(`/php/ajax_handler.php?${param}`)

export function useGetBusinessSettings(withError = false) {
  const param = new URLSearchParams()
  param.append('endpoint', 'setting')
  param.append('action_type', 'get')

  return useQuery<BusinessSettingsResponse>(
    ['get_business_settings', param.toString()],
    () => ajaxHandler(param),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 30, // 30 min
    },
  )
}

//
//TODO rename change the business name
//
export function useMutateBusinessName(object_id: string, withError = false) {
  const param = new URLSearchParams()
  param.append('endpoint', 'business')
  param.append('action_type', 'edit')
  param.append('object_id', object_id)
  const api = useMutation<UseMutateBusinessSettingsReturn, UseMutateBusinessSettingsErrorReturn>(
    `php/ajax_handler.php?${param}`,
    {
      endpoint: '/',
    },
    true,
  )

  return {
    ...api,
    run: (form: BusinessNameRequest) => {
      const formData = new FormData()
      formData.append('postArray', JSON.stringify(form))
      return api.run(
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          //@ts-ignore
          body: new URLSearchParams(formData),
        },
        withError,
      )
    },
  }
}

type TimeZoneResponse = TimeZone[]

interface TimeZone {
  diff_from_GMT: string
  zone: string
}

export function useGetTimeZoneList(withError = false) {
  const param = new URLSearchParams()
  param.append('endpoint', 'timezone_list')
  param.append('action_type', 'get')

  return useQuery<TimeZoneResponse, unknown, SelectOption[]>(
    ['get_timezone_list', param.toString()],
    () => ajaxHandler(param),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      retry: false,
      select(data): SelectOption[] {
        return data.map((z) => ({
          label: z.zone,
          value: z.zone,
        }))
      },
    },
  )
}
